import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class StaticWebsiteCredentialsService {
	USER_EMAIL: string;
	PASSWORD: string;
	constructor() {}

	setCredentials(email: string, password: string) {
		this.USER_EMAIL = email;
		this.PASSWORD = password;
	}
}
