<main class="h-screen w-screen flex justify-center flex-col items-center gap-8">
	<h1 class="text-fontColor text-center text-4xl uppercase">
		{{ "page_not_found" | translate }}
	</h1>
	<img
		src="assets/icons/under_construction_-46-pa.svg"
		class="select-none"
		alt=""
	/>
</main>
