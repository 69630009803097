<main class="h-screen w-screen flex justify-center flex-col items-center gap-8">
	<h1 class="text-fontColor text-center text-4xl uppercase">
		{{ "page_unauthorized" | translate }}
	</h1>
	<img src="assets/icons/under_construction_-46-pa.svg" class="select-none" alt="" />



	<a routerLink="/main"
		class=" mt-8 mx-auto flex flex-row justify-center items-center w-72 h-16 bg-semiLightGray border border-fontColor text-xl text-fontColor rounded-lg shadow-md hover:border hover:border-lightBlue hover:text-lightBlue change-icon">
		{{"SPORT_ACADEMIES.Home" | translate}}
	</a>
</main>